(function () {
    "use strict";

    angular.module('dcms.login', [
        'ngAnimate',
        'ngMap',
        'pascalprecht.translate'
    ]).config(dcmsLoginConfig);

    /** @ngInject */
    function dcmsLoginConfig($translateProvider, config) {

        // --- CONFIGURE TRANSLATIONS ---        
        $translateProvider.useStaticFilesLoader({
            prefix: config.translate.prefix,
            suffix: config.translate.sufix
        });
        $translateProvider.preferredLanguage(config.translate.language)
                .fallbackLanguage(config.translate.fallbackLanguage);
    }

})();