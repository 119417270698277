(function () {
    "use strict";

    angular.module('dcms.login')
            .controller('LoginCtrl', LoginCtrl);

    /** @ngInject */
    function LoginCtrl(dcmsPublicDomainService, $element, $scope) {
        var vm = this;
        
        dcmsPublicDomainService.pullDashboard().then(function(dashboardItems){
            var $banner = $($element).find('.login-banner');
            
            var IMG_PATH = "../data/medialib/" + dashboardItems.banner;
            var img = new Image();
            img.src = IMG_PATH;
            img.onload = function(){
                // read banner from medialib
                $banner.css({
                    'background-image': 'url(' + IMG_PATH + ')'
                });
            };
            
            $scope.location = dashboardItems.location;
        });
    }
})();