"use strict";

(function () {

    angular.module('dcms.login').service('dcmsPublicDomainService',
    /** @ngInject */
    function ($http, $q) {

        function DcmsLoginService() {
            
        }
        
        DcmsLoginService.prototype.pullDashboard = function(){
            var d = $q.defer();
            $http.post("../rest/publicdomain/dashboard-items").then(function(payload){
                var rawArr = payload.data;
                
                var __getItem__ = function(code){
                    for(var i = 0; i < rawArr.length; i++){
                        var obj = rawArr[i];

                        for(var k in obj){
                            var item = obj[k];
                            if(item.code === code)
                                return item.value;
                        }
                    }
                };

                d.resolve({
                    location: __getItem__(3),
                    banner: __getItem__(2),
                    title: __getItem__(1)
                });
            });
            return d.promise;
        };

        return new DcmsLoginService();
    });
})();